import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // {
    //   path: 'auth',
    //   children: [
    //     {
    //       path: 'login',
    //       element: (
    //         <GuestGuard>
    //           <Login />
    //         </GuestGuard>
    //       ),
    //     },
    //     {
    //       path: 'register',
    //       element: (
    //         <GuestGuard>
    //           <Register />
    //         </GuestGuard>
    //       ),
    //     },
    //     { path: 'login-unprotected', element: <Login /> },
    //     { path: 'register-unprotected', element: <Register /> },
    //     { path: 'reset-password', element: <ResetPassword /> },
    //     { path: 'verify', element: <VerifyCode /> },
    //   ],
    // },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        //
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },

        {
          path: 'product',
          children: [
            { element: <Navigate to="/dashboard/product/list" replace />, index: true },
            { path: ':name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <ProductList /> },
            { path: 'new', element: <ProductCreate /> },
            { path: 'edit/:id', element: <ProductCreate /> },
          ],
        },
        {
          path:'sizes',
          children:[
            {element: <Navigate to="/dashboard/sizes/sizewiz" replace />, index: true},
            { path: 'standard_sizes', element: <StandardSizes/> },
            { path: 'sizewiz', element: <SizeWiz /> },
            { path: 'measure_your_body', element: <MeasureYourBody /> },
            { path: 'measure_your_shirt', element: <MeasureYourShirt /> },
          ]
        },
        {
          path: 'product-xml-profile',
          children : [
            { element: <Navigate to={"/dashboard/product-xml-profile/list"} replace/>, index: true},
            { path: ':list', element: <ProductXMLProfileList/>},
          ],
        },

        {
          path: 'category',
          children: [
            { element: <Navigate to="/dashboard/category/list" replace />, index: true },
            { path: ':name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <CategoryList /> },
            { path: 'new', element: <CategoryCreate /> },
            { path: 'edit/:id', element: <CategoryCreate /> },
          ],
        },
        {
          path: 'sub-category',
          children: [
            { element: <Navigate to="/dashboard/sub-category/list" replace />, index: true },
            { path: 'list', element: <SubCategoryList /> },
          ],
        },
        {
          path: 'discount-promotion',
          children: [
            { element: <Navigate to="/dashboard/discount-promotion/list" replace />, index: true},
            { path: 'list', element: <DiscountPromotionList /> },
            { path: 'new', element: <DiscountPromotionCreate /> },
            { path: 'edit/:id', element: <DiscountPromotionCreate /> }
          ]
        },
        {
          path: 'purchased-gift-card',
          children: [
            { element: <Navigate to="/dashboard/gift-card/list" replace />, index: true },
            { path: 'list', element: <PurchasedGiftCardList /> },
            { path: 'new', element: <GenerateGiftCard /> },
            // { path: 'edit/:id', element: <GenerateGiftCard /> },
          ]
        },
        {
          path: 'gift-card',
          children: [
            { element: <Navigate to="/dashboard/gift-card/list" replace />, index: true },
            { path: 'list', element: <GiftCardList /> },
            { path: 'new', element: <GiftCardCreate /> },
            { path: 'edit/:id', element: <GiftCardCreate /> },
          ]
        },

        {
          path: 'collection',
          children: [
            { path: 'list', element: <CollectionList /> },
            { path: 'new', element: <CollectionCreate /> },
            { path: 'edit/:id', element: <CollectionCreate /> },
          ],
        },

        {path: 'attribute-type',
          children: [
            {element: <Navigate to="/dashboard/attribute-type/list" replace />, index:true},
            {path: 'list', element: <AttributeType/>},
            {path: ':name', element: <AttributeTypeDetail/>},
            {path: ':name/new', element: <AttributeTypeDetailCreate/>},
            {path: ':name/edit/:id', element: <AttributeTypeDetailCreate/>}
          ]
        },
        {path: 'attribute-preset',
          children: [
            {path: 'style-preset', element: <AttributeStylePreset/>},
            {path: 'style-preset/new', element: <AttributeStylePresetCreate/>},
            {path: 'style-preset/edit/:id', element: <AttributeStylePresetCreate/>},
            {path: 'sub-style-preset', element: <AttributeSubStylePreset/>},
            {path: 'sub-style-preset/new', element: <AttributeSubStylePresetCreate/>},
            {path: 'sub-style-preset/edit/:id', element: <AttributeSubStylePresetCreate/>},
          ]
        },

        {
          path: 'fabric',
          children: [
            { element: <Navigate to="/dashboard/fabric/list" replace />, index: true },
            { path: ':name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <FabricList /> },
            { path: 'new', element: <FabricCreate /> },
            { path: 'edit/:id', element: <FabricCreate /> },
            { path: 'compatibilityprofile/list', element: <FabricCompatibilityProfileList /> },
            { path: 'compatibilityprofile/new', element: <FabricCompatibilityProfileCreate /> },
            { path: 'compatibilityprofile/:_id/edit', element: <FabricCompatibilityProfileCreate /> },
          ],
        },
        {
          path: 'fabric-properties',
          children: [
            { element: <Navigate to="/dashboard/fabric-properties/list" replace/>, index: true },
            { path: 'list', element:  <FabricProperties/> }
          ]
        },
        {
          path: 'header-menu',
          element: <HeaderMenuCreate/>
        },
        {
          path: 'fabric-category',
          children: [
            {element: <Navigate to="/dashboard/fabric-category/list" replace/>, index: true},
            { path: 'list', element: <FabricCategory/>}
          ]
        },
        {
          path: 'feature',
          children: [
            { element: <Navigate to="/dashboard/feature/new" replace />, index: true },
            { path: 'new', element: <FeatureCreate /> },
          ]
        },

        {
          path: 'product/group',
          children: [
            { element: <Navigate to="/dashboard/product/group/list" replace />, index: true },
            { path: 'list', element: <ProductGroupingList/>},
            { path: 'new', element: <ProductGroupingCreate /> },
            { path: 'edit/:id', element: <ProductGroupingCreate /> },
          ]
        },

        {
          path: 'supplier',
          children: [
            { element: <Navigate to="/dashboard/supplier/list" replace />, index: true },
            { path: 'list', element: <SupplierList/>},
            { path: 'new', element: <SupplierCreate /> },
            { path: 'edit/:id', element: <SupplierCreate /> },

          ]
        },

        {
          path: 'api-configuration',
          children: [
            { element: <Navigate to="/dashboard/api-configuration/list" replace />, index: true },
            { path: 'list', element: <ApiConfigurationList/>},
            { path: 'new', element: <ApiConfigurationCreate /> },
            { path: 'edit/:id', element: <ApiConfigurationCreate /> },

          ]
        },

        {
          path: 'order',
          children: [
            { element: <Navigate to="/dashboard/order/list" replace />, index: true },
            { path: 'list', element: <OrderList /> },
            { path: ':id', element: <OrderDetails /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/list" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
            { path: 'user-role', element: <UserRoles/> },
            { path: 'user-role/create/:name', element: <UserRoleCreate/> },
            { path: 'user-role/edit/:id', element: <UserRoleCreate/> },
          ],
        },
        {
          path: 'customer',
          children: [
            { element: <Navigate to="/dashboard/customer/profile" replace />, index: true },
            { path: 'profile', element: <CustomerProfile /> },
            { path: 'cards', element: <CustomerCards /> },
            { path: 'list', element: <CustomerList /> },
            { path: 'new', element: <CustomerCreate /> },
            { path: ':name/edit', element: <CustomerCreate /> },
            { path: 'account', element: <CustomerAccount /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: ':id', element: <InvoiceDetails /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <GuestGuard>
      <Login />
    </GuestGuard>,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// ATTRIBUTE

const AttributeType = Loadable(lazy(()=>import('../pages/dashboard/AttributeType')));
const AttributeTypeDetail = Loadable(lazy(()=>import('../pages/dashboard/AttributeTypeDetail')));

const AttributeTypeDetailCreate = Loadable(lazy(()=>import('../pages/dashboard/AttributeTypeDetailCreate')));

const AttributeStylePreset = Loadable(lazy(()=>import('../pages/dashboard/AttributeStylePreset')));
const AttributeStylePresetCreate = Loadable(lazy(()=>import('../pages/dashboard/AttributeStylePresetCreate')));

const AttributeSubStylePreset = Loadable(lazy(()=>import('../pages/dashboard/AttributeSubStylePreset')));
const AttributeSubStylePresetCreate = Loadable(lazy(()=>import('../pages/dashboard/AttributeSubStylePresetCreate')));

// Product 
const   ProductList = Loadable(lazy(() => import('../pages/dashboard/ProductList')));
const ProductCreate = Loadable(lazy(() => import('../pages/dashboard/ProductCreate')));

// Sizes
const StandardSizes = Loadable(lazy(() => import('../pages/dashboard/StandardSizes')));
const SizeWiz = Loadable(lazy(() => import('../pages/dashboard/SizeWiz')));
const MeasureYourBody = Loadable(lazy(() => import('../pages/dashboard/MeasureYourBody')));
const MeasureYourShirt = Loadable(lazy(() => import('../pages/dashboard/MeasureYourShirt')));

// E:\Admin Dashboard\PracticeReactProject\mui-minimal-react\src\pages\dashboard\StandardSizes.js

//  Header Menu
const HeaderMenuCreate = Loadable(lazy(()=> import('../pages/dashboard/HeaderListCreate')));

// Category 
const CategoryList = Loadable(lazy(() => import('../pages/dashboard/CategoryList')));
const CategoryCreate = Loadable(lazy(() => import('../pages/dashboard/CategoryCreate')));

// Category 
const SubCategoryList = Loadable(lazy(() => import('../pages/dashboard/SubCategoryList')));
// const SubCategoryCreate = Loadable(lazy(() => import('../pages/dashboard/SubCategoryCreate')));

const GiftCardList = Loadable(lazy(()=> import('../pages/dashboard/GiftCardList')));
const GiftCardCreate = Loadable(lazy(()=> import('../pages/dashboard/GiftCardCreate')));


const GenerateGiftCard = Loadable(lazy(()=> import('../pages/dashboard/GenerateGiftCard')));
const PurchasedGiftCardList = Loadable(lazy(()=> import('../pages/dashboard/PurchasedGiftCardList')));
// const GiftCardGenerate = Loadable(lazy(()=> import('../sections/@dashboard/gift-card/GiftCardGenerateForm')))

// discount and promotions
const DiscountPromotionCreate = Loadable(lazy(()=> import('../pages/dashboard/DiscountPromotionCreate')));
const DiscountPromotionList = Loadable(lazy(()=> import('../pages/dashboard/DiscountPromotionList')));

// Supplier
const SupplierList = Loadable(lazy(()=> import('../pages/dashboard/SupplierList')));
const SupplierCreate = Loadable(lazy(() => import('../pages/dashboard/SupplierCreate')));

// Api Configuration
const ApiConfigurationList = Loadable(lazy(()=> import('../pages/dashboard/ApiConfigurationList')));
const ApiConfigurationCreate = Loadable(lazy(() => import('../pages/dashboard/ApiConfigurationCreate')));

// Fabric 
const FabricList = Loadable(lazy(() => import('../pages/dashboard/FabricList')));
const FabricCreate = Loadable(lazy(() => import('../pages/dashboard/FabricCreate')));
const FabricCompatibilityProfileList = Loadable(lazy(() => import('../pages/dashboard/FabricCompatibilityProfileList')));
const FabricCompatibilityProfileCreate = Loadable(lazy(() => import('../pages/dashboard/FabricCompatibilityProfileCreate')));

// Fabric Category
const FabricCategory = Loadable(lazy(()=> import('../pages/dashboard/FabricCategory')));

const FabricProperties = Loadable(lazy(()=> import('../pages/dashboard/FabricProperties')));
// Feature
const FeatureCreate = Loadable(lazy(() => import('../pages/dashboard/ProductFeatureCreate')));

// Collection
const CollectionList = Loadable(lazy(() => import('../pages/dashboard/CollectionList')));
const CollectionCreate = Loadable(lazy(() => import('../pages/dashboard/CollectionCreate')));


// Grouping
const ProductGroupingList = Loadable(lazy(() => import('../pages/dashboard/ProductGroupingList')));
const ProductGroupingCreate = Loadable(lazy(() => import('../pages/dashboard/ProductGroupingCreate')));

const ProductXMLProfileList = Loadable(lazy(()=> import('../pages/dashboard/ProductXMLProfileList')))

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));


// Order
const OrderList = Loadable(lazy(() => import('../pages/dashboard/OrderList')));
const OrderDetails = Loadable(lazy(() => import('../pages/dashboard/OrderDetails')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// User Role
const UserRoles = Loadable(lazy(()=> import('../pages/dashboard/UserRoles')));
const UserRoleCreate = Loadable(lazy(()=> import('../pages/dashboard/UserRoleCreate')));

// Customer
const CustomerProfile = Loadable(lazy(() => import('../pages/dashboard/CustomerProfile')));
const CustomerCards = Loadable(lazy(() => import('../pages/dashboard/CustomerCards')));
const CustomerList = Loadable(lazy(() => import('../pages/dashboard/CustomerList')));
const CustomerAccount = Loadable(lazy(() => import('../pages/dashboard/CustomerAccount')));
const CustomerCreate = Loadable(lazy(() => import('../pages/dashboard/CustomerCreate')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
