// hook
import { get } from 'react-hook-form';
// components
import { NONE } from 'browserify-zlib/lib/binding';
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';


// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  sizes: getIcon('ic_sizes'),
  finances: getIcon('ic_finances'),
  promotion_sales: getIcon('ic_promotion_sales'),
  attribute_management: getIcon('ic_attribute_management'),
  supplier_management: getIcon('ic_supplier-management'),
  orders: getIcon('ic_orders'),
  catalog_management: getIcon('ic_catalog_management'),
  customers: getIcon('ic_customers'),
  inbox: getIcon('ic_inbox'),
};
const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      // {
      //   title: 'Attribute Management',
      //   icon: ICONS.attribute_management,
      //   children: [
      //     { title: 'Attribute Preset Management', 
      //       children: [
      //         { title: 'Style Preset', path: PATH_DASHBOARD.fabric.list },
      //         { title: 'Sub Style Preset', path: PATH_DASHBOARD.feature.new },
                           
      //       ],
      //     },
      //     { title: 'Attribute Types', path: PATH_DASHBOARD.attribute_management.list },
          
          
      //   ],
      // },
      {
        title: 'Header Menu',
        icon: ICONS.cart,
        path: PATH_DASHBOARD.header_menu.root
      },
      {
        title: 'Attribute Management',
        icon: ICONS.cart,
        children: [
          { title: 'Attribute Type', path: PATH_DASHBOARD.attribute_type.root },
          { title: 'Attribute Preset Management', path: null,
            children : [
              { title : 'Style Preset', path: PATH_DASHBOARD.attribute_preset.stylePreset },
              { title : 'Sub Style Preset', path: PATH_DASHBOARD.attribute_preset.subStylePreset }
            ]
          }
        ],
      },
      {
        title: 'Fabric Management',
        icon: ICONS.cart,
        children: [
          { title: 'Fabrics', path: PATH_DASHBOARD.fabric.list },
          { title: 'Fabrics Category', path: PATH_DASHBOARD.fabricCategory.list },
          { title: 'Fabrics Compatibility Profile', path: PATH_DASHBOARD.fabric.compatibilityprofilelist },
          { title: 'Fabrics Properties', path: PATH_DASHBOARD.fabricProperties.list },
        ],
      },
      {
        title: 'Order Management',
        icon: ICONS.orders,
        children: [
          { title: 'Orders', path: PATH_DASHBOARD.order.list },
          { title: 'Fit Advice Request', path: PATH_DASHBOARD.order.list },
        ],
      },
      {
        title: 'Sizes',
        icon: ICONS.sizes,
        children: [
          { title: 'Sizewiz', path: PATH_DASHBOARD.sizes.sizewiz },
          { title: 'Standard Sizes', path: PATH_DASHBOARD.sizes.standard_sizes },
          { title: 'Measure Your Body', path: PATH_DASHBOARD.sizes.measure_your_body },
          { title: 'Measure Your Shirt', path: PATH_DASHBOARD.sizes.measure_your_shirt },
          
        ],
      },
      {
        title: 'Supplier Management',
        icon: ICONS.supplier_management,
        children: [
          { title: 'Suppliers', path: PATH_DASHBOARD.supplier.list },
          { title: 'Api Configuration', path: PATH_DASHBOARD.apiConfiguration.list },
        ],
      },
      {
        title: 'User Permissions & Roles',
        icon: ICONS.user,
        children: [
          { title: 'Users', path: PATH_DASHBOARD.user.list },
          { title: 'User Roles', path: PATH_DASHBOARD.user.userRole },
        ],
      },
      {
        title: 'Catalog Management',
        icon: ICONS.catalog_management,
        children: [
          { title: 'products', path: PATH_DASHBOARD.product.list },
          { title: 'category', path: PATH_DASHBOARD.category.list },
          { title: 'Sub category', path: PATH_DASHBOARD.sub_category.list },
          { title: 'collection', path: PATH_DASHBOARD.collection.list },
          { title: 'product group', path: PATH_DASHBOARD.grouping.list },
          { title: 'Product XML Profile', path: PATH_DASHBOARD.product_xml.list },
          // { title: 'fabric', path: PATH_DASHBOARD.fabric.list },
          // { title: 'feature', path: PATH_DASHBOARD.feature.new },
          // { title: 'order', path: PATH_DASHBOARD.order.list },
        ],
      },
      {
        title: 'Promotion Sales',
        icon: ICONS.promotion_sales,
        children: [
          { title: 'Discounts/Promotions', path: PATH_DASHBOARD.discount_promotion.list },
          { title: 'Vouchers', path: PATH_DASHBOARD.category.list },
          { title: 'Gift Cards', path: PATH_DASHBOARD.gift_card.list },
          { title: 'Purchase Gift Cards', path: PATH_DASHBOARD.purchased_gift_card.list },
        ],
      },
      {
        title: 'Business Analytics',
        icon: ICONS.analytics,
        children: [
          { title: 'Customer Reports', path: PATH_DASHBOARD.general.analytics },
          { title: 'Sales Reports', path: PATH_DASHBOARD.general.analytics},
        ],
      },
      { title: 'Customers', path: PATH_DASHBOARD.customer.list, icon: ICONS.customers },
      { title: 'Inbox', path: PATH_DASHBOARD.chat.root, icon: ICONS.inbox },
      { title: 'Finances', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.finances },
      // { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     // USER
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //         { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //         { title: 'list', path: PATH_DASHBOARD.user.list },
  //         { title: 'create', path: PATH_DASHBOARD.user.new },
  //         { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
  //         { title: 'account', path: PATH_DASHBOARD.user.account },
  //       ],
  //     },

  //     // E-COMMERCE
  //     {
  //       title: 'e-commerce',
  //       path: PATH_DASHBOARD.eCommerce.root,
  //       icon: ICONS.cart,
  //       children: [
  //         { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //         { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
  //         { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
  //         { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
  //         { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
  //         { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
  //       ],
  //     },

  //     // INVOICE
  //     {
  //       title: 'invoice',
  //       path: PATH_DASHBOARD.invoice.root,
  //       icon: ICONS.invoice,
  //       children: [
  //         { title: 'list', path: PATH_DASHBOARD.invoice.list },
  //         { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
  //         { title: 'create', path: PATH_DASHBOARD.invoice.new },
  //         { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
  //       ],
  //     },

  //     // BLOG
  //     {
  //       title: 'blog',
  //       path: PATH_DASHBOARD.blog.root,
  //       icon: ICONS.blog,
  //       children: [
  //         { title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //         { title: 'post', path: PATH_DASHBOARD.blog.demoView },
  //         { title: 'create', path: PATH_DASHBOARD.blog.new },
  //       ],
  //     },
  //   ],
  // },

  // // APP
  // // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: (
  //         <Label variant="outlined" color="error">
  //           +32
  //         </Label>
  //       ),
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
  //   ],
  // },
];

export default navConfig;
